import { Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import TagProducts from "../../ProductTwo/tags/tag-products";
import MapTagProducts from "../../ProductTwo/tags/map-tag-products";

function TagInfo() {
  const navigate = useNavigate();
  const location = useLocation();
  const tag = location.state != null ? location.state.item : undefined;

  useEffect(() => {
    console.log(location.state);
  }, []);

  return (
    <>
      {tag !== undefined && (
        <main className="container-fluid">
          <div className="row m-3">
            <div className="row justify-content-around tble">
              <div className="col-6">
                <h3 className="dashboard-title">{tag.name}</h3>
              </div>
              <div className="mr-auto col-6 text-end">
                <button
                  type="button"
                  className="mr-auto btn"
                  onClick={() => navigate(0)}
                >
                  Refresh
                </button>

                <button
                  type="button"
                  className="mr-auto btn btn-danger"
                  onClick={() => navigate(-1)}
                >
                  BACK
                </button>
              </div>
            </div>
          </div>
          <div className="row m-3 mt-0">
            <div className="col-12 mt-4">
              <Tabs
                defaultActiveKey="products"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="products" title="Products">
                  <TagProducts id={tag.id} />
                </Tab>
                <Tab eventKey="mapproduct" title="Assign Product">
                  <MapTagProducts id={tag.id} />
                </Tab>
              </Tabs>
            </div>
          </div>
        </main>
      )}
    </>
  );
}

export default TagInfo;
