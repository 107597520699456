import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAction as taxsListAction } from "../../network/store/action/TaxResponseAction";
import { listAction as colorListAction } from "../../network/store/action/ColorsResponseAction";
import { listAction as uomListAction } from "../../network/store/action/UomResponseAction";

import "./products.css";
import { useNavigate } from "react-router-dom";
import { updateMessage } from "../../redux/slice/toastSlice";
import AppToast from "../../components/appToast";
import { priceCreateUpdateAction } from "../../network/store/action/ProductPriceAction";
import { formUpdateData } from "../../redux/slice/formUpdateSlice";

function ProductPriceUpdate() {
  // to get api data

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formData = useSelector(formUpdateData);
  const itemInfo = formData.item;

  const [taxList, setTaxsList] = useState([]);
  const [colorList, setColorsList] = useState([]);
  const [slab, setSlab] = useState({});
  const [selectedColor, setSelectedColor] = useState({});
  const [uomList, setUomList] = useState([]);

  const [productAddForm, setproductAddForm] = useState({
    taxId: "",
    colorId: "",
    uomId: "",
    quantity: "",
    price: "",
    offer: "",
    maxQantity: "",
    sku: "",
  });

  useEffect(() => {
    console.log("itemInfo", itemInfo);
    const existingObj = {
      taxId: itemInfo.slab_id,
      colorId: itemInfo.color_id,
      uomId: itemInfo.measure,
      quantity: itemInfo.qty,
      price: itemInfo.price,
      offer: itemInfo.offer,
      maxQantity: itemInfo.user_max_quantity,
      sku: itemInfo.sku,
    };
    console.log("existingObj ====>", existingObj);
    setproductAddForm(existingObj);
    gettaxList();
    getColorsList();
    getUomList();
  }, []);

  const getUomList = async () => {
    await dispatch(uomListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setUomList(response.result);
      }
    });
  };

  const gettaxList = async () => {
    await dispatch(taxsListAction({}, dispatch)).then((response) => {
      // console.log("setTaxsList", response.result);
      if (response.result) {
        setTaxsList(response.result);
        if (itemInfo.slab_id != undefined) {
          const selectedSlab = response.result.find(
            (slab) => slab.id === itemInfo.slab_id
          );
          console.log("selectedSlab", selectedSlab);
          setSlab(selectedSlab);
        }
      }
    });
  };

  const getColorsList = async () => {
    await dispatch(colorListAction({}, dispatch)).then((response) => {
      //   console.log("sectionList", response.result);
      if (response.result) {
        setColorsList(response.result);
      }
    });
  };

  const handleChanges = (e) => {
    //

    if (e.target.name === "taxId") {
      const selectedSlab = taxList.find((slab) => slab.id === e.target.value);

      setSlab(selectedSlab);
    }

    if (e.target.name === "colorId") {
      const selectedColor = colorList.find((obj) => obj.id === e.target.value);
      setSelectedColor(selectedColor);
    }

    setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
  };

  const onSubmtClick = async () => {
    if (checkValidation()) {
      let itemObj = {
        price: productAddForm.price,
        qty: productAddForm.quantity,
        measure: productAddForm.uomId,
        offer: productAddForm.offer,
        c_gst: slab.cgst,
        s_gst: slab.sgst,
        slabId: slab.id,
        colorId: selectedColor.id,
        colorCode: selectedColor.color_code,
        colorName: selectedColor.name,
        sku: productAddForm.sku,
        productId: itemInfo.product_id,
        sQuantity: 20,
        sIndicator: 20,
        pPrice: 0,
        id: itemInfo.id,
      };

      await dispatch(priceCreateUpdateAction(itemObj, dispatch)).then(
        (response) => {
          if (response.result) {
            dispatch(
              updateMessage({
                display: true,
                message: "Price Updated.",
              })
            );
            navigate(-1);
          }
        }
      );
    } else {
      dispatch(
        updateMessage({
          display: true,
          message: "Form Error.",
        })
      );
    }
  };

  const checkValidation = () => {
    var isFormValid = true;
    if (
      !productAddForm.taxId ||
      !productAddForm.colorId ||
      !productAddForm.uomId ||
      !productAddForm.quantity ||
      !productAddForm.price ||
      !productAddForm.offer ||
      !productAddForm.maxQantity
    ) {
      isFormValid = false;
    }
    return isFormValid;
  };

  return (
    <>
      <AppToast />
      <main className="container-fluid dashboard">
        <div className="row justify-content-around tble">
          <div style={{ marginLeft: "50px" }}>
            <h3 className="dashboard-title">Update Price</h3>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-3">
            <div className="form-group">
              <label>Tax Slab</label>
              <select
                name="taxId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.taxId}
              >
                <option value={""}>{"--Select Tax Slab--"}</option>
                {taxList &&
                  taxList.length > 0 &&
                  taxList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              <div className="col-auto">
                <label>SGST: {slab.sgst} %</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <label>CGST: {slab.cgst} %</label>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Color</label>
              <select
                name="colorId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.colorId}
              >
                <option value={""}>{"--Select Color--"}</option>
                {colorList &&
                  colorList.length > 0 &&
                  colorList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name} ({item.color_code} )
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>SKU</label>
              <input
                type="text"
                name="sku"
                className="form-control"
                value={productAddForm.sku}
                onChange={(e) => handleChanges(e)}
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Quantity</label>
              <input
                type="text"
                name="quantity"
                className="form-control"
                value={productAddForm.quantity}
                onChange={(e) => handleChanges(e)}
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>UOM (Measure)</label>
              <select
                name="uomId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.uomId}
              >
                <option value={""}>{"--Select UOM--"}</option>
                {uomList &&
                  uomList.length > 0 &&
                  uomList.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Price</label>
              <input
                type="text"
                name="price"
                className="form-control"
                value={productAddForm.price}
                onChange={(e) => handleChanges(e)}
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Offer (%)</label>
              <input
                type="text"
                name="offer"
                className="form-control"
                value={productAddForm.offer}
                onChange={(e) => handleChanges(e)}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>User purchase Max Quantity</label>
              <input
                type="text"
                name="maxQantity"
                className="form-control"
                value={productAddForm.maxQantity}
                onChange={(e) => handleChanges(e)}
              />
            </div>
          </div>

          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              style={{ display: "flex", justifyContent: "center" }}
              type="button"
              className="btn btn-purple-bg"
              onClick={() => onSubmtClick()}
            >
              Submit
            </button>
            <button className="btn btn-white-bg" onClick={() => navigate(-1)}>
              Cancel
            </button>
          </div>
        </div>
      </main>
    </>
  );
}
export default ProductPriceUpdate;
