import { useEffect, useRef, useState } from "react";
import { FaImage, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updateFormInfo } from "../../redux/slice/formUpdateSlice";
import { createUpdateAction } from "../../network/store/action/SectionResponseAction";
import { updateMessage } from "../../redux/slice/toastSlice";
import noImage from "../../assets/no_image.jpg";
import {
  onChangeData,
  onChangeDelete,
} from "../../redux/slice/selectedItemsSlice";
import { categoryImageUplaodRequest } from "../../network/service/ImageUploadService";

const CategoryItem = (props) => {
  const [item, setItem] = useState(props.item);
  
  const [itemStatus, setitemStatus] = useState(item.status);
  const dispatch = useDispatch();

  const [file, setFile] = useState();
  const hiddenFileInput = useRef(null);

  const editItem = () => {
    dispatch(updateFormInfo({ displayForm: true, formData: { item } }));
  };

  const onStatusChange = async () => {
    let status = "YES";
    if (item.status == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      id: item.id,
    };

    await dispatch(createUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        setItem(item, (item.status = status));
        setitemStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  const onImageClick = () => {
    hiddenFileInput.current.click();
  };

  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(URL.createObjectURL(fileUploaded));
      uploadImage(fileUploaded);
    }
  };

  const uploadImage = async (file) => {
    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      id: item.id,
      fileData: form_data,
    };
    await dispatch(categoryImageUplaodRequest(requestBody)).then(
      (response) => {}
    );
  };

  return (
    <tr>
      <td className="align-middle">
        <p>{item.id}</p>
      </td>
      <td className="align-middle">
        <div className="" onClick={() => onImageClick()}>
          <input
            type="file"
            onChange={handleImageInputChange}
            ref={hiddenFileInput}
            style={{ display: "none" }} // Make the file input element invisible
          />
          {file ? (
            <>
              <img
                src={file}
                alt={item.name}
                className="img-thumbnail"
                width="50px"
                height="50px"
              />
            </>
          ) : item.image_path !== "" ? (
            <img
              src={item.image_path}
              alt={item.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          ) : (
            <img
              src={noImage}
              alt={item.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          )}
        </div>
      </td>
      <td className="align-middle">
        <p>{item.name}</p>
      </td>

      <td className="align-middle">
        {/* <p>{item.status}</p> */}
        <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onStatusChange}
            checked={itemStatus === "YES" ? true : false}
          />
        </div>
      </td>
      <td className="align-middle">
        <p className="col-lg-12 d-flex justify-content-center">
          <span
            className="edit-icon-btn"
            onClick={() => {
              editItem();
            }}
          >
            <FaRegEdit />
          </span>
          {/* <span className="delete-icon-btn">
            <FaTrash />
          </span> */}
        </p>
      </td>
    </tr>
  );
};

export default CategoryItem;
