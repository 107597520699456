import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Protected from "./pages/protected-route";
import Dashboard from "./pages/Home/Dashboard";
import Login from "./auth/login";
import Tags from "./pages/tags/tags";
import DailyReport from "./pages/reports/DalyReports";
import WeeklyReport from "./pages/reports/WeeklyReport";
import MonthlyReport from "./pages/reports/MonthlyReport";
import YearlyReport from "./pages/reports/YearlyReport";
import CustomReport from "./pages/reports/CustomReports";
import Banner from "./pages/banner/banner";
import Category from "./pages/category/category";
import SubCategory from "./pages/subcategory/subcategory";
import Brands from "./pages/brands/brands";
import Store from "./pages/Store/Store";
import ChangePassword from "./auth/ChangePassword";
import TaxSlab from "./pages/tax/tax-slab";
import HsnCodes from "./pages/hsn/hsn";
import Colors from "./pages/colors/colors";
import MaterialType from "./pages/material/MaterialType";
import Uom from "./pages/uom/uom";
import ProductUpdate from "./pages/products/product-update-form";
import ProductPriceAdd from "./pages/products/product-price-add-form";
import ProductPriceUpdate from "./pages/products/product-price-update-form";
import ProductTwo from "./pages/ProductTwo/products";
import MultiStepForm from "./pages/ProductTwo/product-create";
import CreateOrder from "./pages/order/CreateOrder";
import ProductAdd from "./pages/products/product-add-form";
import BannerCreate from "./pages/banner/banner-create";
import StoreAddUpdateForm from "./pages/Store/store-add-update-";
import ProductDetails from "./pages/product-detail/product-detail";
import PriceEntryForm from "./pages/ProductTwo/price-edit-form";
import ProductPriceCreate from "./pages/price/product-price-create";
import Orders from "./pages/order/oreders";
import OrderDetails from "./pages/order/order-details";
import Customer from "./pages/users/customers/customers";
import StoreAdmin from "./pages/users/store-admin/store-admin";
import AllCoupons from "./pages/coupons/All/All-Coupons";
import LocationCoupons from "./pages/coupons/Location/location-coupons";
import StoreCoupons from "./pages/coupons/Stores/store-coupons";
import NewCoupon from "./pages/coupons/All/add-new-coupon";
import NewStoreCoupon from "./pages/coupons/Stores/add-stores-coupon";
import NewLocationCoupon from "./pages/coupons/Location/add-location-coupon";
import StoreInfo from "./pages/Store/info/StoreInfo";
import B2BCustomers from "./pages/users/b2bcustomers/b2bcustomers";
import AddB2BUser from "./pages/users/b2bcustomers/add-update-b2b-user";
import DeUsers from "./pages/users/delivery-boys/deusers";
import AddDEUser from "./pages/users/delivery-boys/add-update-de-user";
import AddSEUser from "./pages/users/store-executives/add-update-se-user";
import SeUsers from "./pages/users/store-executives/seusers";
import SEmployees from "./pages/users/store-employees/s-employee";
import AddSEmployee from "./pages/users/store-employees/add-update-semployee";
import AddAdminUser from "./pages/users/admin/add-update-admin";
import AdminUsers from "./pages/users/admin/admin-users";
import DealerUsers from "./pages/users/dealers/dealer-users";
import AddDealerUser from "./pages/users/dealers/add-update-dealer";
import AddCustomers from "./pages/users/customers/add-update-customer";
import AddUpdateCoupon from "./pages/coupons/All/add-update-coupon";
import AddUpdateStoreCoupon from "./pages/coupons/Stores/add-update-store-coupon";
import Dimension from "./pages/dimension/dimension";
import StorePriceUpdate from "./pages/price/store/store-price-update";
import AdminPriceUpdate from "./pages/price/admin/admin-price-update";
import ProductTagUpdate from "./pages/ProductTwo/product-update-tags";
import TagInfo from "./pages/tags/details/TagInfo";
import AdminInlineProducts from "./pages/ProductTwo/admin-pirce-inline-update";
import StoreInlineProducts from "./pages/ProductTwo/store/store-pirce-inline-update";
import Expenses from "./pages/daily/expenses/expenses";
import Purchases from "./pages/daily/purchases/purchases";
import Sales from "./pages/daily/sales/sales";
import Wallet from "./pages/wallet/wallet";
import Settings from "./pages/settings/settings";
import UserAttendance from "./pages/attendance/user-attendance";
import UserAttendanceLog from "./pages/attendance/user-attendance-log";
import GMapsPage from "./pages/maps/gmaps";
import ItemSales from "./pages/daily/sales/item-sales";
import TodayLoginUsers from "./pages/attendance/today-login-users";
import FirebaseMapsPage from "./pages/maps/firebasemaps";

export default function Routers() {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/login" />} />

      <Route
        path="/dashboard"
        element={<Protected>{<Dashboard />}</Protected>}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/banners" element={<Banner />} />
      <Route path="/categories" element={<Category />} />
      <Route path="/subcategories" element={<SubCategory />} />
      <Route path="/tags" element={<Tags />} />
      <Route path="/products" element={<ProductTwo />} />
      <Route path="/corder" element={<CreateOrder />} />

      <Route path="/porders" element={<Orders />} />
      <Route path="/orderDetails" element={<OrderDetails />} />
      <Route path="/dreports" element={<DailyReport />} />
      <Route path="/wreports" element={<WeeklyReport />} />
      <Route path="/mreports" element={<MonthlyReport />} />
      <Route path="/yreports" element={<YearlyReport />} />
      <Route path="/creports" element={<CustomReport />} />
      <Route path="/brands" element={<Brands />} />
      <Route path="/stores" element={<Store />} />
      <Route path="/changepassword" element={<ChangePassword />} />
      <Route path="/taxSlabs" element={<TaxSlab />} />
      <Route path="/hsn" element={<HsnCodes />} />
      <Route path="/colors" element={<Colors />} />
      <Route path="/mtypes" element={<MaterialType />} />
      <Route path="/cuprdct" element={<MultiStepForm />} />
      <Route path="/udprdct" element={<ProductUpdate />} />
      <Route path="/udprice" element={<ProductPriceUpdate />} />
      <Route path="/uom" element={<Uom />} />
      <Route path="/crprice" element={<ProductPriceAdd />} />
      <Route path="/addBanner" element={<BannerCreate />} />
      <Route path="/pinfo" element={<ProductDetails />} />
      <Route path="/scu" element={<StoreAddUpdateForm />} />
      <Route path="/priceupdate" element={<ProductPriceCreate />} />

      <Route path="/customers" element={<Customer />} />
      <Route path="/b2bc" element={<B2BCustomers />} />
      <Route path="/storeAdmins" element={<StoreAdmin />} />
      <Route path="/deliveryBoys" element={<DeUsers />} />
      <Route path="/decu" element={<AddDEUser />} />
      <Route path="/beu" element={<SeUsers />} />
      <Route path="/secu" element={<AddSEUser />} />
      <Route path="/sempcu" element={<AddSEmployee />} />
      <Route path="/semp" element={<SEmployees />} />
      <Route path="/acu" element={<AddAdminUser />} />
      <Route path="/admin" element={<AdminUsers />} />
      <Route path="/dcu" element={<AddDealerUser />} />
      <Route path="/dealers" element={<DealerUsers />} />
      <Route path="/usercu" element={<AddCustomers />} />

      <Route path="/all" element={<AllCoupons />} />

      <Route path="/locationCoupons" element={<LocationCoupons />} />

      <Route path="/storecoupons" element={<StoreCoupons />} />

      <Route path="/addAllCoupon" element={<AddUpdateCoupon />} />

      <Route path="/addStoreCoupon" element={<AddUpdateStoreCoupon />} />

      <Route path="/addLocationCoupon" element={<NewLocationCoupon />} />

      <Route path="/sInfo" element={<StoreInfo />} />
      <Route path="/b2ba" element={<AddB2BUser />} />

      <Route path="/dimensions" element={<Dimension />} />
      <Route path="/spu" element={<StorePriceUpdate />} />
      <Route path="/apu" element={<AdminPriceUpdate />} />

      <Route path="/ptags" element={<ProductTagUpdate />} />
      <Route path="/tagInfo" element={<TagInfo />} />

      <Route path="/apc" element={<AdminInlineProducts />} />
      <Route path="/spc" element={<StoreInlineProducts />} />
      <Route path="/expenses" element={<Expenses />} />
      <Route path="/purchases" element={<Purchases />} />
      <Route path="/sales" element={<Sales />} />
      <Route path="/wallet" element={<Wallet />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/attendance" element={<UserAttendance />} />
      <Route path="/alog" element={<UserAttendanceLog />} />
      <Route path="/gmaps" element={<GMapsPage />} />
      <Route path="/fmaps" element={<FirebaseMapsPage />} />
      <Route path="/oilog" element={<ItemSales />} />
      <Route path="/tls" element={<TodayLoginUsers />} />
    </Routes>
  );
}
