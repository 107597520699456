import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listAction as brandListAction } from "../../network/store/action/BrandResponseAction";
import { listAction as taxsListAction } from "../../network/store/action/TaxResponseAction";
import { listAction as hsnListAction } from "../../network/store/action/HsnResponseAction";
import { listAction as materialListAction } from "../../network/store/action/MaterialResponseAction";
import { listAction as categoryListAction } from "../../network/store/action/SectionResponseAction";

import "./products.css";
import { useNavigate } from "react-router-dom";
import { createUpdateAction } from "../../network/store/action/ProductResponseAction";
import { updateMessage } from "../../redux/slice/toastSlice";

const ProductInfoForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sectionList, setSectionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [brandnList, setBrandList] = useState([]);
  const [taxList, setTaxsList] = useState([]);
  const [hsnList, setHsnList] = useState([]);
  const [colorList, setColorsList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [slab, setSlab] = useState({});
  const [selectedColor, setSelectedColor] = useState({});
  const [uomList, setUomList] = useState([]);

  const [productAddForm, setproductAddForm] = useState({
    brandId: "",
    taxId: "",
    materialId: "",
    hsnId: "",
    name: "",
    description: "",
    status: "YES",
    sectionId: "",
    categoryId: "",
    sku: "",
  });

  useEffect(() => {
    getSections();
    getBrandList();
    gettaxList();
    getHsnList();
    getMaterialList();
  }, []);

  const getSections = async () => {
    await dispatch(categoryListAction({}, dispatch)).then((response) => {
      //   console.log("sectionList", response.result);
      if (response.result) {
        setSectionList(response.result);
      }
    });
  };

  const getBrandList = async () => {
    await dispatch(brandListAction({}, dispatch)).then((response) => {
      // console.log("sectionList", response.result);
      if (response.result) {
        setBrandList(response.result);
      }
    });
  };

  const gettaxList = async () => {
    await dispatch(taxsListAction({}, dispatch)).then((response) => {
      // console.log("setTaxsList", response.result);
      if (response.result) {
        setTaxsList(response.result);
      }
    });
  };

  const getHsnList = async () => {
    await dispatch(hsnListAction({}, dispatch)).then((response) => {
      //   console.log("sectionList", response.result);
      if (response.result) {
        setHsnList(response.result);
      }
    });
  };

  const getMaterialList = async () => {
    await dispatch(materialListAction({}, dispatch)).then((response) => {
      //   console.log("sectionList", response.result);
      if (response.result) {
        setMaterialList(response.result);
      }
    });
  };

  const handleChanges = (e) => {
    //

    if (e.target.name === "sectionId") {
      const sectionIdex = sectionList.findIndex(
        (obj) => obj.id === e.target.value
      );
      if (e.target.value == "") {
        setCategoryList([]);
      } else {
        setCategoryList(sectionList[sectionIdex].categories);
      }
      //  setCategoryList(sectionList[sectionIdex].categories);
    }

    // if (e.target.name === "taxId") {
    //   const selectedSlab = taxList.find((slab) => slab.id === e.target.value);

    //   setSlab(selectedSlab);
    // }

    setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
  };

  const onSubmtClick = async () => {
    if (checkValidation()) {
      let itemObj = {
        name: productAddForm.name,
        description: productAddForm.description,
        brand: productAddForm.brandId,
        materialType: productAddForm.materialId,
        status: "YES",
        hsn: productAddForm.hsnId,
        taxId: productAddForm.taxId,
        sectionId: productAddForm.sectionId,
        categoryId: productAddForm.categoryId,
        sku: productAddForm.sku,
      };
      await dispatch(createUpdateAction(itemObj, dispatch)).then((response) => {
        if (response.result) {
          dispatch(
            updateMessage({
              display: true,
              message: "Product Created.",
            })
          );
          if (props.onSubmitClick) props.onSubmitClick(response.result.id);
        }
      });
    } else {
      dispatch(
        updateMessage({
          display: true,
          message: "Form Error.",
        })
      );
    }
  };

  // || !productAddForm.categoryId

  const checkValidation = () => {
    var isFormValid = true;
    if (
      !productAddForm.name ||
      !productAddForm.description ||
      !productAddForm.materialId ||
      !productAddForm.brandId ||
      !productAddForm.taxId ||
      !productAddForm.hsnId ||
      !productAddForm.sectionId
      // ||!productAddForm.categoryId
    ) {
      isFormValid = false;
    }
    return isFormValid;
  };

  return (
    <>
      <div className="d-flex align-item-center justify-content-center">
        <div className="col-md-8">
          <div className="container">
            <div className="row mt-5 mb-5 card-1" style={{ padding: "30px" }}>
              <h3 className="text-center">Product Information</h3>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group m-0">
                    <label>HSN CODE</label>
                    <select
                      name="hsnId"
                      className="form-select"
                      onChange={(e) => handleChanges(e)}
                      value={productAddForm.hsnId}
                    >
                      <option value={""}>{"--Select HSN--"}</option>
                      {hsnList &&
                        hsnList.length > 0 &&
                        hsnList.map((item, index) => {
                          return (
                            <option key={index} value={item.code}>
                              {item.code}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group m-0">
                    <label>TAX</label>
                    <select
                      name="taxId"
                      className="form-select"
                      onChange={(e) => handleChanges(e)}
                      value={productAddForm.taxId}
                    >
                      <option value={""}>{"--Select Tax Slab--"}</option>
                      {taxList &&
                        taxList.length > 0 &&
                        taxList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group m-0">
                    <label>Brand</label>
                    <select
                      name="brandId"
                      className="form-select"
                      onChange={(e) => handleChanges(e)}
                      value={productAddForm.brandId}
                    >
                      <option value={""}>{"--Select Brand--"}</option>
                      {brandnList &&
                        brandnList.length > 0 &&
                        brandnList.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group m-0">
                    <label>Material Type</label>
                    <select
                      name="materialId"
                      className="form-select"
                      onChange={(e) => handleChanges(e)}
                      value={productAddForm.materialId}
                    >
                      <option value={""}>{"--Select Type--"}</option>
                      {materialList &&
                        materialList.length > 0 &&
                        materialList.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Section</label>
                    <select
                      name="sectionId"
                      className="form-select"
                      onChange={(e) => handleChanges(e)}
                      value={productAddForm.sectionId}
                    >
                      <option value={""}>{"--Select Section--"}</option>
                      {sectionList &&
                        sectionList.length > 0 &&
                        sectionList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>Category</label>
                    <select
                      name="categoryId"
                      className="form-select"
                      onChange={(e) => handleChanges(e)}
                      value={productAddForm.categoryId}
                    >
                      <option value={""}>{"--Select Category--"}</option>
                      {categoryList &&
                        categoryList.length > 0 &&
                        categoryList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group m-0">
                    <label>SKU Code</label>
                    <input
                      type="text"
                      name="sku"
                      className="form-control"
                      value={productAddForm.sku}
                      onChange={(e) => handleChanges(e)}
                    />
                  </div>
                </div> */}

                <div className="col-md-6">
                  <div className="form-group m-0">
                    <label>Item Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={productAddForm.name}
                      onChange={(e) => handleChanges(e)}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group m-0">
                    <label>Description</label>
                    <textarea
                      type="text"
                      name="description"
                      className="form-control"
                      value={productAddForm.description}
                      onChange={(e) => handleChanges(e)}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                >
                  <button
                    style={{ display: "flex", justifyContent: "center" }}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => onSubmtClick()}
                  >
                    Submit
                  </button>

                  <button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginLeft: "15px",
                    }}
                    type="button"
                    className="btn"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductInfoForm;
