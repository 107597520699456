import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listAction,
  storeCatListRequestAction,
} from "../../../network/store/action/SectionResponseAction";

import StoreCategoryItem from "./store-category-item";

function StoreCategory(props) {
  const dispatch = useDispatch();

  // list of items
  const [resultList, setResultList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  //const selectedCatIds = useSelector(selectedItemsStatus);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    await dispatch(
      storeCatListRequestAction({ storeId: props.id }, dispatch)
    ).then((reponse) => {
      if (reponse.result) {
        setResultList(reponse.result);
      }
    });
  };

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);
    if (searchTerm === "") {
      setResultList(resultList);
      setFilterList([]);
    } else {
      const filterBySearch = resultList.filter((item) => {
        if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return item;
        }
      });
      setFilterList(filterBySearch);
    }
  }

  return (
    <div className="row m-3">
      <div className="col-md-3">
        <div className="form-group">
          <label>Search Category</label>
          <input
            type="search"
            name="name"
            className="form-control"
            value={searchVal}
            placeholder="Enter category name"
            onChange={(e) => handleSearchClick(e)}
          />
        </div>
      </div>
      <div className="col-md-12 card-1">
        <table
          className="table table-striped table-bordered border-sm  mt-2"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th scope="col" class="col-1">
                {" "}
                Id
              </th>
              <th scope="col" class="col-2">
                Image
              </th>
              <th scope="col" class="col-3">
                Name
              </th>
              <th scope="col" class="col-1">
                Status
              </th>
            </tr>
          </thead>
          {searchVal === "" && resultList && resultList.length > 0 ? (
            <tbody>
              {resultList.map((item, i) => {
                return (
                  <StoreCategoryItem
                    item={item}
                    key={item.id}
                    storeId={props.id}
                  />
                );
              })}
            </tbody>
          ) : filterList.length > 0 ? (
            <tbody>
              {filterList.map((item, i) => {
                return (
                  <StoreCategoryItem
                    item={item}
                    key={item.id}
                    storeId={props.id}
                  />
                );
              })}
            </tbody>
          ) : (
            <p>No Data found</p>
          )}
        </table>
      </div>
    </div>
  );
}

export default StoreCategory;
