function Expenses() {
  return (
    <main className="container-fluid dashboard">
      <div className="row m-3">
        <div className="row justify-content-around tble">
          <div className="col-6">
            <h3 className="dashboard-title">Expenses </h3>
          </div>
          <div className="mr-auto col-6 text-end">
            <button
              type="button"
              className="mr-auto btn btn-purple-bg"
              onClick={() => {}}
            >
              + Add Category
            </button>
          </div>
        </div>
      </div>

      <div className="row m-3"></div>
    </main>
  );
}
export default Expenses;
