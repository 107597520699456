import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createUpdateAction,
  getMainBranchAction,
} from "../../network/store/action/BranchResponseAction";
import {
  formUpdateData,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { updateMessage } from "../../redux/slice/toastSlice";
import { useLocation, useNavigate } from "react-router-dom";
//import Autocomplete from "react-google-autocomplete";
import AutoComplete from "react-google-autocomplete";

const StoreAddUpdateForm = (props) => {
  // TO get Callbacks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const formData = useSelector(formUpdateData);
  const itemInfo = location.state != null ? location.state.item : undefined;
  const [cityName, setCity] = useState("");
  const [stateName, setStateName] = useState("");
  const [country, setCountry] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [landmark, setLandmark] = useState("");
  const [isMainBranch, setMainBranch] = useState(false);
  const [isMainBranchDisplay, setMainBranchDisplay] = useState(false);

  // // Page Form
  const [dataForm, setDataForm] = useState({
    name: "",
    address: "",
    radius: 0,
    cName: "",
    cPhoneOne: "",
    cPhonetwo: "",
    free_delivery_min: "",
    delivery_charge: "",
    //latitude: 0,
    //longitude: 0,
    mail: "",
    //  landmark: "",
  });

  useEffect(() => {
    if (itemInfo != undefined) {
      let updateValues = {
        name: itemInfo.name,
        address: itemInfo.address,
        radius: itemInfo.radius,
        cName: itemInfo.contact_name,
        cPhoneOne: itemInfo.contact_one,
        cPhonetwo: itemInfo.contact_two,
        mail: itemInfo.mail,
        free_delivery_min: itemInfo.free_delivery_min,
        delivery_charge: itemInfo.delivery_charge,
      };
      setDataForm(updateValues);
      setCity(itemInfo.city);
      setLatitude(itemInfo.latitude);
      setLongitude(itemInfo.longitude);
      setLandmark(itemInfo.landmark);
    }
    loadData();
  }, []);

  const loadData = async () => {
    await dispatch(getMainBranchAction(dispatch)).then((response) => {
      if (response != null) {
        setMainBranchDisplay(response.status);
        setMainBranch(response.status);
      }
    });
  };

  const handleInputChanges = (e) => {
    if (e.target.name === "radius") {
      const selectedRadius = e.target.value.slice(0, 2);
      setDataForm({
        ...dataForm,
        [e.target.name]: selectedRadius,
      });
    } else if (e.target.name === "cPhoneOne" || e.target.name === "cPhonetwo") {
      const selected = e.target.value.slice(0, 10);
      setDataForm({
        ...dataForm,
        [e.target.name]: selected,
      });
    } else if (
      e.target.name === "free_delivery_min" ||
      e.target.name === "delivery_charge"
    ) {
      const selected = e.target.value.slice(0, 5);
      setDataForm({
        ...dataForm,
        [e.target.name]: selected,
      });
    } else {
      setDataForm({
        ...dataForm,
        [e.target.name]: e.target.value,
      });
    }
  };
  const onStatusChange = async () => {
    setMainBranch(isMainBranch ? false : true);
  };

  const updatePlaceInfo = (place) => {
    if (place && place.address_components != null) {
      console.log(place);
      // setDataForm({
      //   ...dataForm,
      //   latitude: place.geometry.location.lat(),
      //   longitude: place.geometry.location.lng(),
      //   landmark: place.formatted_address,
      // });

      setLatitude(place.geometry.location.lat());
      setLongitude(place.geometry.location.lng());
      setLandmark(place.formatted_address);

      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        switch (addressType) {
          case "locality":
            setCity(place.address_components[i].long_name);
            break;
          case "administrative_area_level_1":
            setStateName(place.address_components[i].long_name);
            break;
          case "country":
            setCountry(place.address_components[i].long_name);
            break;
        }
      }
    }
  };

  const labelStyle = {
    fontWeight: "bold",
  };

  const onSubmtClick = async () => {
    let requestBody = {
      name: dataForm.name,
      address: dataForm.address,
      mail: dataForm.mail,
      latitude: latitude,
      longitude: longitude,
      landmark: landmark,
      radius: dataForm.radius,
      cName: dataForm.cName,
      phoneOne: dataForm.cPhoneOne,
      phoneTwo: dataForm.cPhonetwo,
      city: cityName,
      isMainBranch:
        isMainBranchDisplay === false
          ? isMainBranch === true
            ? "YES"
            : "NO"
          : "NO",
      dCharge: dataForm.delivery_charge,
      free_delivery_min: dataForm.free_delivery_min,
    };

    if (itemInfo != undefined) {
      requestBody.id = itemInfo.id;
    }

    await dispatch(createUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        navigate(-1);
        // if (props.onSubmit) props.onSubmit();
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <>
      <main className="container-fluid dashboard">
        <div className="row justify-content-around tble">
          <div className="col-md-8 card-1 p-5">
            {itemInfo != undefined ? (
              <h4 className="text-center">Update Branch</h4>
            ) : (
              <h4 className="text-center"> Add Branch</h4>
            )}
            <div className="row m-4">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Branch Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={dataForm.name}
                    onChange={(e) => handleInputChanges(e)}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Branch Address</label>
                  <textarea
                    type="text"
                    name="address"
                    className="form-control"
                    value={dataForm.address}
                    onChange={(e) => handleInputChanges(e)}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Mail ID</label>
                  <input
                    type="text"
                    name="mail"
                    className="form-control"
                    value={dataForm.mail}
                    onChange={(e) => handleInputChanges(e)}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label>Contact Name</label>
                  <input
                    type="text"
                    name="cName"
                    className="form-control"
                    value={dataForm.cName}
                    onChange={(e) => handleInputChanges(e)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Contact Phone</label>
                  <input
                    maxLength="10"
                    type="number"
                    name="cPhoneOne"
                    className="form-control"
                    value={dataForm.cPhoneOne}
                    onChange={(e) => handleInputChanges(e)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Alternate Contact Number</label>
                  <input
                    maxLength="10"
                    type="number"
                    name="cPhonetwo"
                    className="form-control"
                    value={dataForm.cPhonetwo}
                    onChange={(e) => handleInputChanges(e)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Serving Radius</label>
                  <input
                    type="number"
                    name="radius"
                    maxLength="2"
                    className="form-control"
                    value={dataForm.radius}
                    onChange={(e) => handleInputChanges(e)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Delvery Charges</label>
                  <input
                    type="number"
                    name="delivery_charge"
                    maxLength="3"
                    className="form-control"
                    value={dataForm.delivery_charge}
                    onChange={(e) => handleInputChanges(e)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Min. Amount for Free Delivery</label>
                  <input
                    type="number"
                    name="free_delivery_min"
                    maxLength="5"
                    className="form-control"
                    value={dataForm.free_delivery_min}
                    onChange={(e) => handleInputChanges(e)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group m-0">
                  <label>Landmark</label>{" "}
                  <AutoComplete
                    options={{
                      types: ["geocode", "establishment"],
                    }}
                    defaultValue={landmark}
                    className="form-control"
                    style={{ width: "90%" }}
                    name="landmark"
                    onChange={(e) => handleInputChanges(e)}
                    apiKey={"AIzaSyDSOtXI8CvDwqWfP6_JbV0tsmj5ymSPL8k"}
                    onPlaceSelected={(place) => updatePlaceInfo(place)}
                    placeholder="Enter city name"
                  />
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="form-group">
                  <label>Latitude</label>
                  <input
                    type="number"
                    name="latitude"
                    className="form-control"
                    value={dataForm.latitude}
                    onChange={(e) => handleInputChanges(e)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Longitude</label>
                  <input
                    type="number"
                    name="longitude"
                    className="form-control"
                    value={dataForm.longitude}
                    onChange={(e) => handleInputChanges(e)}
                  />
                </div>
              </div> */}

              {isMainBranchDisplay === false ? (
                <>
                  <div className="col-md-4">
                    <div className="form-group justify-content-center">
                      <input
                        class="form-check-input "
                        type="checkbox"
                        role="switch"
                        onChange={onStatusChange}
                        checked={isMainBranch}
                      />
                      <label style={{ marginLeft: "10px", fontSize: "14px" }}>
                        Head Offcice Branch
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{ display: "flex", justifyContent: "center" }}
                  type="button"
                  className="btn btn-purple-bg"
                  onClick={() => onSubmtClick()}
                >
                  Submit
                </button>
                <button
                  className="btn btn-white-bg"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default StoreAddUpdateForm;
