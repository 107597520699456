import React, { useState } from "react";
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { orderFormatedDate, orderFormatedDateTime } from "../../utils/dateUtil";
import AppIcon from "../../assets/logo_icon.png";

function FMarkerWithInfowindow(props) {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  console.log("FMarkerWithInfowindow", props.item);

  function statusText(status) {
    switch (status) {
      case "AVAILABLE":
        return <span style={{ color: "green" }}>AVAILABLE</span>;

      case "BUSY":
        return <span style={{ color: "violet" }}>BUSY</span>;

      case "OFF":
        return <span style={{ color: "red" }}>OFF</span>;
    }
  }
  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => setInfowindowOpen(true)}
        position={{
          lat: parseFloat(props.item.latitude),
          lng: parseFloat(props.item.longitude),
        }}
        title={props.item.userName}
      >
        <img
          src={AppIcon}
          width={50}
          height={50}
          className="card-1"
          style={{ padding: "5px" }}
        />
      </AdvancedMarker>
      {infowindowOpen && (
        <InfoWindow
          anchor={marker}
          maxWidth={250}
          minWidth={250}
          onCloseClick={() => setInfowindowOpen(false)}
          headerContent={<h6>Transchem</h6>}
        >
          <div className="d-flex">
            <img
              src={AppIcon}
              width={50}
              height={50}
              style={{ padding: "5px" }}
            />
            <div>
              <h6>{props.item.userName}</h6>
              Last updated Time
              <br />
              <b>{orderFormatedDateTime(props.item.updatedAt)}</b>
            </div>
          </div>
        </InfoWindow>
      )}
    </>
  );
}

export default FMarkerWithInfowindow;
