import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { ORDER_API_PATH } from "../../network/config/apiPaths";
import OrdersItem from "./order-item";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { changeStatus } from "../../redux/slice/loaderSlice";
import { useDispatch } from "react-redux";
import { isAfterDate } from "../../utils/dateUtil";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";
function Orders() {
  const dispatch = useDispatch();

  const [orderResult, setOrderResult] = useState([]);

  const [statusList, setStatusList] = useState([
    { id: "0", name: "Pending" },
    { id: "1", name: "Accepted" },
    { id: "2", name: "Assgned" },
    { id: "3", name: "Picked-Up" },
    { id: "4", name: "Delivered" },
    { id: "6", name: "Cancelled" },
  ]);

  //Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const [filterForm, setFilterForm] = useState({
    status: "",
    startDate: "",
    endDate: "",
  });

  const navigate = useNavigate();
  useEffect(() => {
    OrderList();
  }, [filterForm]);

  async function OrderList() {
    if (filterForm.endDate.length > 0) {
      datesValidation();
    } else {
      makeApiCall();
    }
  }

  const makeApiCall = async () => {
    dispatch(changeStatus(true));
    const data = await axios.get(
      `${API_CONFIG.API_HOST}${ORDER_API_PATH.ORDER_LIST}?limit=500&offset=0&status=${filterForm.status}&fromDate=${filterForm.startDate}&toDate=${filterForm.endDate}`,
      {
        headers: API_HEADERS,
      }
    );
    dispatch(changeStatus(false));
    setOrderResult(data.data.result);
  };

  const handleChanges = (e) => {
    setFilterForm({ ...filterForm, [e.target.name]: e.target.value });
  };

  const datesValidationOnChnage = (endDate) => {
    // if (new Date(formData.startDate) > new Date(to)) {
    //   alert("Select Valid Dates");
    // }

    if (
      filterForm.startDate.length > 0 &&
      endDate > 0 &&
      !isAfterDate(filterForm.startDate, endDate)
    ) {
      alert("Select Valid Dates");
    }
  };

  const datesValidation = () => {
    let to = filterForm.endDate;

    // if (new Date(formData.startDate) > new Date(to)) {
    //   alert("Select Valid Dates");
    // }

    if (
      filterForm.startDate.length > 0 &&
      filterForm.endDate.length > 0 &&
      !isAfterDate(filterForm.startDate, to)
    ) {
      alert("Select Valid Dates");
    } else {
      makeApiCall();
    }
  };

  return (
    <>
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Orders</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              {/* <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                 
                }}
              >
                + Add Category
              </button> */}
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-3">
            <div className="form-group">
              <label>Search</label>
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Enter customer mobile no"
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>Order Status</label>
              <select
                name="status"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={filterForm.status}
              >
                <option value={""}>{"--Select Status--"}</option>
                {statusList &&
                  statusList.length > 0 &&
                  statusList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div
            className={
              filterForm.startDate.length > 0 ? "col-md-5" : "col-md-2"
            }
          >
            <Row>
              <Col>
                <FormGroup>
                  <Label for="startDate">From Date</Label>
                  <Input
                    type="date"
                    name="startDate"
                    id="startDate"
                    placeholder=""
                    value={filterForm.startDate}
                    onChange={handleChanges}
                    // min={new Date().toISOString().split("T")[0]}
                  />
                </FormGroup>
              </Col>
              {filterForm.startDate.length > 0 && (
                <Col>
                  <FormGroup>
                    <Label for="endDate">To Date</Label>
                    <Input
                      type="date"
                      name="endDate"
                      id="endDate"
                      placeholder=""
                      value={filterForm.endDate}
                      onChange={handleChanges}
                      // min={new Date().toISOString().split("T")[0]}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          </div>
          {/* <div className="col-md-2">
            <div className="form-group">
              <label></label>
              <button>Clear Flters</button>
            </div>
          </div> */}
          <div className="col-md-12 card-1">
            <table className="table table-striped table-bordered border-sm  mt-2">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Branch</th>
                  <th scope="col">Order Time</th>
                  <th scope="col">Order Amount</th>
                  <th scope="col">Customer Details </th>
                  <th scope="col">Coupon</th>
                  <th scope="col">Status </th>
                  <th scope="col">Payment Mode </th>
                  <th scope="col">Last Update Time</th>
                </tr>
              </thead>

              <tbody>
                {orderResult && orderResult.length > 0 ? (
                  orderResult.map((obj) => {
                    return <OrdersItem order={obj} key={obj.id} />;
                  })
                ) : (
                  <>No Orders</>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
}
export default Orders;
