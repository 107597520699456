import { useNavigate } from "react-router-dom";
import CouponsItem from "./all-coupon-item";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import {
  BANNER_API_PATH,
  COUPONS_API_PATH,
} from "../../../network/config/apiPaths";
import { useDispatch, useSelector } from "react-redux";
import { deleteStatus } from "../../../redux/slice/formUpdateSlice";
import { updateMessage } from "../../../redux/slice/toastSlice";
import { changeStatus } from "../../../redux/slice/loaderSlice";

function AllCoupons() {
  const navigate = useNavigate();
  const [apiResult, setApiResult] = useState([]);
  const dispatch = useDispatch();
  const showDeleteAlert = useSelector(deleteStatus);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    AllCoupons();
  }, []);

  const AllCoupons = async () => {
    setIsLoading(true);
    dispatch(changeStatus(true));
    const data = await axios.get(
      API_CONFIG.API_HOST + COUPONS_API_PATH.COUPON_LIST_PATH + "ALL",
      {
        headers: API_HEADERS,
      }
    );
    setIsLoading(false);
    dispatch(changeStatus(false));

    if (data.data.result) setApiResult(data.data.result);
  };

  return (
    <>
      {/* {showDeleteAlert && (
        <DeleteAlert
          onSubmit={() => {
            onSubmit();
          }}
        />
      )} */}

      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Coupons</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  navigate("/addAllCoupon");
                }}
              >
                + Add New
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-12 card-1">
            <table className="table table-striped table-bordered border-sm  mt-2 align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Code</th>
                  <th scope="col" colSpan={3}>
                    Coupon Discount
                  </th>
                  <th scope="col" colSpan={4}>
                    Validity
                  </th>
                  <th scope="col" colSpan={2}>
                    Unique Users
                  </th>
                  <th scope="col" colSpan={2}>
                    Usage Limit
                  </th>
                  <th scope="col">Usage Count</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>

                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col">Min. Purchase</th>
                  <th scope="col">Offer</th>
                  <th scope="col"> Max. Discount</th>
                  <th scope="col">Days Limit</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Unique</th>
                  <th scope="col">Max. Claims</th>
                  <th scope="col">Limit</th>
                  <th scope="col">Max. Users</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {isLoading ? (
                  <>Loading</>
                ) : apiResult && apiResult.length > 0 ? (
                  <>
                    {apiResult.map((obj, i) => {
                      return (
                        <CouponsItem item={obj} sno={i + 1} key={obj.id} />
                      );
                    })}
                  </>
                ) : (
                  <>No data</>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default AllCoupons;
