import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listAction } from "../../network/store/action/SectionResponseAction";
import noImage from "../../assets/no_image.jpg";
import { listAction as productListAction } from "../../network/store/action/ProductResponseAction";
import { FaEdit, FaPlus } from "react-icons/fa";
import { storeListAction } from "../../network/store/action/ProductResponseAction";
import { listAction as branchListAction } from "../../network/store/action/BranchResponseAction";
import { listAction as userListAction } from "../../network/store/action/UserResponseAction";
import Select from "react-select";

function CreateOrder() {
  const dispatch = useDispatch();
  const [resultList, setResultList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [branches, setBranchList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userResult, setUserResult] = useState([]);

  const [productAddForm, setproductAddForm] = useState({
    sectionId: "",
    categoryId: "",
    storeId: "",
  });

  useEffect(() => {
    loadData();
    getStores();
  }, []);

  useEffect(() => {
    getProducts(
      productAddForm.sectionId,
      productAddForm.categoryId,
      productAddForm.storeId,
      selectedOption !== null ? selectedOption.id : ""
    );
  }, [
    productAddForm.sectionId,
    productAddForm.categoryId,
    productAddForm.storeId,
  ]);

  useEffect(() => {
    if (productAddForm.storeId !== "") {
      getUsers(productAddForm.storeId);
    }
  }, [productAddForm.storeId]);

  const getUsers = async (storeId) => {
    await dispatch(
      userListAction(
        {
          orgId: productAddForm.storeId,
          roleId: 9,
        },
        dispatch
      )
    ).then((response) => {
      if (response.result) {
        setUserResult(response.result);
      }
    });
  };

  const getStores = async () => {
    await dispatch(branchListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setBranchList(response.result);
      }
    });
  };
  const loadData = async () => {
    await dispatch(listAction(dispatch)).then((reponse) => {
      if (reponse.result) {
        setproductAddForm({
          ...productAddForm,
          categoryId: reponse.result[0].id,
          sectionId: "",
        });
        setResultList(reponse.result);
      }
    });
  };

  const getProducts = async (sectionId, categoryId, storeId, userId) => {
    if (sectionId !== "") {
      await dispatch(
        storeListAction(
          {
            limit: 100,
            offset: 0,
            sectionId: sectionId,
            categoryId: categoryId,
            storeId: storeId,
            userId: userId,
          },
          dispatch
        )
      ).then((reponse) => {
        if (reponse.result) {
          setProductList(reponse.result);
        } else {
          setProductList([]);
        }
      });
    } else {
      setProductList([]);
    }
  };

  const handleChanges = (e) => {
    if (e.target.name === "sectionId") {
      const sectionIdex = resultList.findIndex(
        (obj) => obj.id === e.target.value
      );
      if (sectionIdex != -1) {
        setCategoryList(resultList[sectionIdex].categories);
      } else {
        setCategoryList([]);
      }
      setproductAddForm({
        ...productAddForm,
        categoryId: "",
        sectionId: e.target.value,
      });
    } else {
      setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
    }

    //  getProducts(pageLimit,pageOffset, e.target.name === 'sectionId'?e.target.value : '', e.target.name === 'categoryId'?e.target.value : '');
    //  setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value })
  };

  return (
    <main className="container-fluid dashboard nopadding">
      <div className="row m-3">
        <div className="col-md-12">
          <h3 className="dashboard-title">Create Order</h3>
        </div>
      </div>

      <div className="row m-3">
        <div className="col-md-2">
          <div className="form-group">
            <label>Branch</label>
            <select
              name="storeId"
              className="form-select"
              onChange={(e) => handleChanges(e)}
              value={productAddForm.storeId}
            >
              <option value={""}>{"--Select Branch--"}</option>
              {branches &&
                branches.length > 0 &&
                branches.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="col-md-3">
          <div className="form-group">
            <label>Customer</label>
            <Select
              options={userResult}
              value={selectedOption !== null ? selectedOption : ""}
              getOptionLabel={(option) =>
                `${option.business_name} - ${option.name}`
              }
              getOptionValue={(option) => `${option.id}`}
              onChange={(sOption) => {
                setSelectedOption(sOption);
              }}
              // isMulti
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label>Category</label>
            <select
              name="sectionId"
              className="form-select"
              onChange={(e) => handleChanges(e)}
              value={productAddForm.sectionId}
            >
              <option value={""}>{"--Select Category--"}</option>
              {resultList &&
                resultList.length > 0 &&
                resultList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label>Sub Category</label>
            <select
              name="categoryId"
              className="form-select"
              onChange={(e) => handleChanges(e)}
              value={productAddForm.categoryId}
            >
              <option value={""}>{"--Select Sub Category--"}</option>
              {categoryList &&
                categoryList.length > 0 &&
                categoryList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>

      <div className="row m-3">
        <div
          className="col-md-4 card-1 overflow-auto"
          style={{ padding: "10px" }}
        >
          <h5>Products</h5>

          {productList && productList.length > 0 ? (
            <>
              {productList.map((product, i) => {
                return (
                  <div className="container mb-2 ">
                    <div>
                      <p>{product.item.name}</p>
                    </div>

                    <div className="grid-container">
                      {product.prices &&
                        product.prices.length > 0 &&
                        product.prices.map((price, i) => {
                          return (
                            <div>
                              {price.qty} {price.measure}{" "}
                            </div>
                          );
                        })}
                    </div>

                    {/* <div className="grid-container">
                      <p>{product.item.name}</p>
                      <br></br>
                      {product.prices &&
                        product.prices.length > 0 &&
                        product.prices.map((price, i) => {
                          return (
                            <>
                              <div className="price-box position-rproductative m-1">
                                <span
                                  className="float-edit-btn"
                                  onClick={() => {}}
                                >
                                  <FaPlus />
                                </span>
                                <span>
                                  {price.qty} {price.measure}{" "}
                                </span>
                                <br />
                                <span>
                                  {price.offer_price !== "" ? (
                                    <>
                                      <b>{"₹" + price.offer_price}</b>{" "}
                                      <span
                                        style={{
                                          textDecoration: "line-through",
                                          color: "grey",
                                        }}
                                      >
                                        {"₹" + price.price}
                                      </span>
                                    </>
                                  ) : (
                                    <b>{"₹" + price.price}</b>
                                  )}{" "}
                                </span>
                              </div>
                            </>
                          );
                        })}
                    </div> */}
                  </div>
                );
              })}
            </>
          ) : (
            <p>No Data found</p>
          )}
        </div>
      </div>
    </main>
  );
}

export default CreateOrder;
