import { useState } from "react";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updateFormInfo } from "../../redux/slice/formUpdateSlice";
import { createUpdateAction } from "../../network/store/action/TagResponseAction";
import { updateMessage } from "../../redux/slice/toastSlice";
import { Link, useNavigate } from "react-router-dom";
const Tagtem = (props) => {
  const [item] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.status);
  const [homeStatus, setHomeStatus] = useState(item.display_option);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const infoPage = () => {
    navigate("/tagInfo", {
      state: {
        item,
      },
    });
  };

  const editItem = () => {
    console.log(item);
    dispatch(updateFormInfo({ displayForm: true, formData: { item } }));
  };

  const onStatusChange = async () => {
    let status = "YES";
    if (item.status == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      id: item.id,
    };

    await dispatch(createUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        item.status = status;
        setitemStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  const onHomeStatusChange = async () => {
    let status = "NONE";
    if (item.display_option == "NONE") {
      status = "HOME";
    } else {
      status = "NONE";
    }

    let requestBody = {
      display_option: status,
      id: item.id,
    };

    await dispatch(createUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        item.display_option = status;
        setHomeStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <tr key={item.id}>
      <td className="align-middle">
        <p>{item.id}</p>
      </td>
      <td
        className="align-middle"
        onClick={() => {
          infoPage();
        }}
      >
        <Link>
          <p>{item.name}</p>
        </Link>
      </td>

      <td className="align-middle">
        <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onStatusChange}
            checked={itemStatus === "YES" ? true : false}
          />
        </div>
      </td>

      <td className="align-middle">
        <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onHomeStatusChange}
            checked={homeStatus === "HOME" ? true : false}
          />
        </div>
      </td>
      <td className="align-middle">
        <p className="col-lg-12 d-flex justify-content-center">
          <span
            className="edit-icon-btn"
            onClick={() => {
              {
                editItem();
              }
            }}
          >
            <FaRegEdit />
          </span>{" "}
          {/* <span className="delete-icon-btn">
            <FaTrash />
          </span> */}
        </p>
      </td>
    </tr>
  );
};

export default Tagtem;
