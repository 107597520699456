import { FaStrikethrough } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  displayCancelAlert,
  displayCancelStatus,
  formUpdateData,
} from "../../redux/slice/formUpdateSlice";
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { ORDER_API_PATH } from "../../network/config/apiPaths";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getStatusText } from "../../utils/order-util";
import { updateStatusRequestAction } from "../../network/store/action/OrderResponseAction";
import { updateMessage } from "../../redux/slice/toastSlice";
import { orderFormatedDate } from "../../utils/dateUtil";
import { orderInvoiceRequest } from "../../network/service/ImageUploadService";
import { listAction } from "../../network/store/action/UserResponseAction";
import AlertCancelOrder from "../../components/AlertCancelOrder";
import { changeStatus } from "../../redux/slice/loaderSlice";

function OrderDetails() {
  //const formData = useSelector(formUpdateData);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const order = location.state != null ? location.state.order : undefined; // check state of order.
  const orderId = location.state != null ? location.state.orderId : undefined;
  const [response, setResponse] = useState({});
  const [resultList, setResultList] = useState({});
  const [file, setFile] = useState();
  const [ordId, setOrdId] = useState(
    orderId !== undefined ? orderId : order != undefined ? order.id : ""
  );

  const showCancelAlert = useSelector(displayCancelStatus);

  const [filterOptions, setfilterOptions] = useState({
    userId: "",
  });

  const [dataForm, setDataForm] = useState({
    inum: "",
  });

  useEffect(() => {
    orderDetailsApi();
  }, []);

  const loadData = async (orgId) => {
    await dispatch(
      listAction(
        {
          orgId: orgId,
          roleId: "3,4,7",
        },
        dispatch
      )
    ).then((reponse) => {
      if (reponse.result) {
        setResultList(reponse.result);
      } else {
        setResultList([]);
      }
    });
  };

  const handleInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(fileUploaded);
    }
  };

  const uploadFile = async () => {
    console.log("dataForm.inum", dataForm.inum);
    if (file != null) {
      dispatch(changeStatus(true));
      const form_data = new FormData();
      form_data.append("file", file);
      let requestBody = {
        id: response.info.id,
        inum: dataForm.inum,
        fileData: form_data,
      };
      await dispatch(orderInvoiceRequest(requestBody)).then((response) => {
        dispatch(changeStatus(false));
        navigate(0);
        // orderDetailsApi();
      });
    } else {
      alert("Upload File.");
    }
  };

  const handleChanges = (e) => {
    setfilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
  };

  const orderDetailsApi = async () => {
    const data = await axios.get(
      API_CONFIG.API_HOST + ORDER_API_PATH.ORDER_DETAILS + `${ordId}`,
      {
        headers: API_HEADERS,
      }
    );

    setResponse(data.data.result);
    loadData(data.data.result.storeItems[0].store.store_id);
  };

  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
  };
  function Status(status) {
    switch (status) {
      case "0":
        return <span style={{ color: "black" }}>PENDING</span>;

      case "1":
        return <span style={{ color: "pinkAccent" }}>ACCEPTED</span>;

      case "2":
        return <span style={{ color: "blueAccent" }}>ASSIGNED</span>;

      case "3":
        return <span style={{ color: "violet" }}>PICKED UP</span>;
      case "4":
        return <span style={{ color: "green" }}>DELIVERED</span>;
      case "5":
        return <span style={{ color: "green" }}>DELIVERED</span>;
      case "6":
        return <span style={{ color: "red" }}>CANCELLED</span>;
      case "7":
        return <span style={{ color: "black" }}>REJECTED</span>;
      case "8":
        return (
          <span style={{ color: "0xffcc0d0d" }}>ORDER CANCEL REQUEST</span>
        );
    }
  }

  const handleInputChanges = (e) => {
    console.log(e.target.name, e.target.value);
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const updateStatus = async (statusCode) => {
    const requestBody = {
      orderId: response.info.id,
      dbId: filterOptions.userId,
      // dbId: response.info.created_by,
      updatedBy: localStorage.getItem("userId"),
      userId: response.info.user_id,
      status: statusCode,
      statusText: getStatusText(statusCode),
      notes: getStatusText(statusCode),
    };

    //console.log("requestBody", requestBody);
    await dispatch(updateStatusRequestAction(requestBody)).then((response) => {
      if (response.status == true) {
        orderDetailsApi();
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <>
      {showCancelAlert && (
        <AlertCancelOrder
          onSubmit={() => {
            orderDetailsApi();
          }}
        />
      )}
      {isObjectEmpty(response) ? (
        <></>
      ) : (
        <main className="container-fluid dashboard">
          <div className="row m-3">
            <div className="col-lg-12">
              <div className="row justify-content-around tble">
                <div className="col-6">
                  <h5>{response.storeItems[0].store.name}</h5>
                </div>
                <div className="mr-auto col-6 text-end">
                  <button
                    type="button"
                    className="mr-auto btn btn-danger"
                    onClick={() => navigate(-1, { replace: false })}
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-4 r-flex col-md-4 card-1"
                style={{
                  marginLeft: "1rem",
                  marginTop: "1rem",
                  padding: "20px",
                }}
              >
                {/* <h5>Order Details</h5> */}
                <div>
                  <b>Order No:</b> #TRCM0{response.info.id}
                </div>
                <div>
                  <b>Date:</b> {response.info.created_at.substring(0, 10)}
                </div>
                <div>
                  <b>Time:</b> {response.info.created_at.substring(11)}
                </div>
              </div>
              <div
                className="col-lg-4 r-flex  card-1"
                style={{
                  marginLeft: "1rem",
                  marginTop: "1rem",
                  padding: "20px",
                }}
              >
                <h5>{Status(response.info.order_status)}</h5>
                <div>
                  {response.payment[0].pay_mode}

                  {response.info.pay_option && (
                    <>
                      {" -- "}
                      <b style={{ fontSize: "16px", color: "forestgreen" }}>
                        {response.info.pay_option} - {response.info.pay_status}
                      </b>
                    </>
                  )}
                </div>

                {response.storeItems[0].store.dbName !== null && (
                  <div>
                    Assigned To:{" "}
                    <b>
                      {response.storeItems[0].store.dbName} /
                      {response.storeItems[0].store.dbPhone}
                    </b>
                  </div>
                )}
              </div>
              <div
                className="col-lg-3  r-flex  card-1"
                style={{
                  marginLeft: "1rem",
                  marginTop: "1rem",
                  padding: "20px",
                }}
              >
                {/* <h5>Customer Info</h5> */}
                <div>
                  Created By{" "}
                  {response.info.orderCreatedBy != undefined ? (
                    <>
                      {response.info.orderCreatedBy}
                      {" - "} {response.info.orderCreatedByPhone}
                    </>
                  ) : (
                    <> -- </>
                  )}
                </div>
                <div>
                  <b>
                    {response.info.business_name && (
                      <> {response.info.business_name}</>
                    )}
                    <br></br>
                    {response.info.customerName}
                    {" - "}
                    {response.info.phone}
                  </b>
                </div>

                <div>{response.address.landmark} </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-lg-12">
                <div className="">
                  <div class="accordion" id="Order_Items">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Order Items
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        data-parent="#Order_Items"
                      >
                        <div class="accordion-body table-responsive">
                          <table className="table table-striped table-bordered border-sm  mt-2">
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">S.NO</th>
                                <th scope="col">Name</th>
                                <th scope="col">UOM</th>
                                <th scope="col">Item Nos.</th>
                                <th scope="col">Order Quantity</th>
                                <th scope="col">Price</th>
                              </tr>
                            </thead>
                            {response.storeItems[0].items &&
                            response.storeItems[0].items.length > 0 ? (
                              <tbody>
                                {response.storeItems[0].items.map((item, i) => {
                                  // return <>{item.item.id}</>;
                                  return (
                                    <tr>
                                      <td className="align-middle">
                                        <p> {i + 1}</p>
                                      </td>
                                      <td className="align-middle">
                                        <p> {item.name}</p>
                                      </td>
                                      <td className="align-middle">
                                        <p>
                                          {item.size} {item.measure}
                                        </p>
                                      </td>
                                      <td className="align-middle">
                                        <p>{item.quantity} NOs</p>
                                      </td>

                                      <td className="align-middle">
                                        <p>
                                          {item.size * item.quantity}{" "}
                                          {item.measure}
                                        </p>
                                      </td>

                                      <td className="align-middle">
                                        <p>
                                          {Math.round(item.item_total * 100) /
                                            100}
                                        </p>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <p>No Data found</p>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12" style={{ marginTop: "20px" }}>
                <div class="accordion" id="Order_Track">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        Order Track
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse show"
                      data-parent="#Order_Track"
                    >
                      <div class="accordion-body table-responsive">
                        <div>
                          <table className="table table-striped table-bordered border-sm  mt-2">
                            <thead className="thead-dark">
                              <tr>
                                {/* <th scope="col">S.NO</th> */}
                                <th scope="col">Status</th>
                                <th scope="col">Updated At</th>
                                <th scope="col">Updated Location</th>
                              </tr>
                            </thead>
                            {response.storeItems[0].logs &&
                            response.storeItems[0].logs.length > 0 ? (
                              <tbody>
                                {response.storeItems[0].logs.map((item, i) => {
                                  // return <>{item.item.id}</>;
                                  return (
                                    <tr>
                                      {/* <td className="align-middle">
                                        <p> {i}</p>
                                      </td> */}
                                      <td className="align-middle">
                                        <p> {item.status_text}</p>
                                      </td>
                                      <td className="align-middle">
                                        <p>
                                          {orderFormatedDate(item.created_at)}
                                        </p>
                                      </td>

                                      <td className="align-middle">
                                        <p>
                                          {" "}
                                          {item.latitude > 0 ? (
                                            item.address
                                          ) : (
                                            <> Transchem Office</>
                                          )}
                                        </p>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <p>No Data found</p>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
              <div className="col-lg-12">
                <div>
                  <div class="accordion" id="Order_Payment">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Payment
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse show"
                        data-parent="#Order_Payment"
                      >
                        <div class="accordion-body">
                          <div>
                            <ol>
                              <li>
                                Mode of payment : {response.payment[0].pay_mode}
                              </li>
                              {/* <li>
                                Settlement Status -{" "}
                                {response.payment[0].pay_mode}
                              </li> */}
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12" style={{ marginTop: "20px" }}>
                <div>
                  <div class="accordion" id="Order_bill">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Bill Details
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse show"
                        data-parent="#Order_bill"
                      >
                        <div class="accordion-body">
                          <div
                            className="col-lg-12"
                            style={{
                              padding: "10px",
                              marginLeft: "10px",
                              marginRight: "250px",
                              marginTop: "10px",
                            }}
                          >
                            {/* <h5>Bill Details</h5> */}
                            <div className="row">
                              <div className="col-lg-6">Items Price</div>
                              <div className="col-lg-6 text-end">
                                {response.info.items_cost !=
                                response.info.item_total ? (
                                  <span className="strike-off">
                                    ₹{response.info.items_cost}
                                  </span>
                                ) : (
                                  <></>
                                )}
                                ₹{response.info.item_total}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">Tax</div>
                              <div className="col-lg-6 text-end">
                                ₹{" "}
                                {Math.round(response.info.total_tax * 100) /
                                  100}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">Delivery Charges</div>
                              <div className="col-lg-6 text-end">
                                ₹{+response.info.delivery_charge}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">Coupon</div>
                              <div className="col-lg-6 text-end">
                                ₹{response.info.coupon_amount}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">
                                <b>Total Price</b>{" "}
                              </div>
                              <div className="col-lg-6 text-end">
                                <b>₹{+response.info.total_roundoff}</b>
                              </div>
                            </div>
                          </div>
                          {response.info.invoice_file_path &&
                            response.info.order_status <= 4 && (
                              <div className="row col-md-8 mt-20">
                                <div className="col-md-2">
                                  <div className="form-group m-0">
                                    <input
                                      type="text"
                                      name="inum"
                                      placeholder="Enter Invoice No."
                                      className="form-control"
                                      value={dataForm.inum}
                                      onChange={(e) => handleInputChanges(e)}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <input
                                    type="file"
                                    onChange={handleInputChange}
                                    accept="application/pdf"
                                  />
                                </div>
                                <div className="col-md-3">
                                  <button
                                    type="button"
                                    class="mr-auto btn btn-purple-bg me-2"
                                    onClick={() => {
                                      uploadFile();
                                    }}
                                  >
                                    Upload Invoice
                                  </button>
                                </div>
                              </div>
                            )}
                          <div className="row">
                            {/* <div className="col-lg-6"></div> */}
                            <div className="col-lg-12 text-end">
                              {response.invoices &&
                                response.invoices.length > 0 &&
                                response.info.order_status <= 4 && (
                                  <ul className="list-unstyled">
                                    {response.invoices.map((item, i) => {
                                      return (
                                        <li>
                                          <div className="row">
                                            <div className="col-md-11">
                                              <p>{item.invoice_number}</p>
                                            </div>
                                            <div className="col-md-1">
                                              <Link
                                                to={item.invoice_image_path}
                                                target="_blank"
                                                download
                                              >
                                                Download
                                              </Link>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  // <Link
                                  //   to={response.info.invoice_file_path}
                                  //   target="_blank"
                                  //   download
                                  // >
                                  //   Download Invoice
                                  // </Link>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-3 d-flex">
              <div class="mr-auto col-12 text-center">
                {/* <button
                  type="button"
                  class="btn btn-danger me-2"
                  onClick={() => navigate("/orders")}
                >
                  Cancel
                </button> */}

                {response.info.order_status === "0" && (
                  <button
                    type="button"
                    class="mr-auto btn btn-purple-bg me-2"
                    onClick={() => {
                      updateStatus(1);
                    }}
                  >
                    Accept
                  </button>
                )}

                {response.info.order_status === "1" && (
                  <div>
                    {response.info.invoice_file_path === "" && (
                      <div className="row col-md-8">
                        <div className="col-md-2">
                          <div className="form-group m-0">
                            <input
                              type="text"
                              name="inum"
                              className="form-control"
                              placeholder="Enter Invoice No."
                              value={dataForm.inum}
                              onChange={(e) => handleInputChanges(e)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <input
                            type="file"
                            onChange={handleInputChange}
                            accept="application/pdf"
                          />
                        </div>
                        <div className="col-md-3">
                          <button
                            type="button"
                            class="mr-auto btn btn-purple-bg me-2"
                            onClick={() => {
                              uploadFile();
                            }}
                          >
                            Upload Invoice
                          </button>
                        </div>
                      </div>
                    )}
                    {response.info.invoice_file_path && (
                      <div className="row m-3 col-md-12">
                        <div className="col-md-3">
                          <select
                            name="userId"
                            className="form-select"
                            onChange={(e) => handleChanges(e)}
                            value={filterOptions.userId}
                          >
                            <option value={""}>{"--Select Executive--"}</option>
                            {resultList &&
                              resultList.length > 0 &&
                              resultList.map((item, index) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>

                        <div className="col-md-3">
                          <button
                            type="button"
                            class="mr-auto btn btn-purple-bg me-2"
                            onClick={() => {
                              if (filterOptions.userId !== "") {
                                updateStatus(2);
                              } else {
                                alert("Select Executive");
                              }
                            }}
                          >
                            Assign
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {response.info.order_status <= 4 && (
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={() => {
                      dispatch(
                        displayCancelAlert({
                          displayCancel: true,
                          formData: response,
                        })
                      );
                    }}
                  >
                    Cancel Order
                  </button>
                )}
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
}
export default OrderDetails;
