import React from "react";
import {
  deletePath,
  displayCancelAlert,
  displayRemoveAlert,
  formUpdateData,
  itemType,
} from "../redux/slice/formUpdateSlice";
import { useDispatch, useSelector } from "react-redux";
import { API_CONFIG, API_HEADERS } from "../network/config/ApiConfig";
import { axiosApi } from "../network/service/config/AaxiosUtil";
import { updateStatusRequestAction } from "../network/store/action/OrderResponseAction";
import { getStatusText } from "../utils/order-util";
import { updateMessage } from "../redux/slice/toastSlice";
import { INVOICE_PREFIX } from "../utils/app-constants";

function AlertCancelOrder(props) {
  const dispatch = useDispatch();

  const formData = useSelector(formUpdateData);
  const optionType = useSelector(itemType);
  const deleteApiPath = useSelector(deletePath);
  const response = formData;
  console.log("response", formData);
  const updateStatus = async (statusCode) => {
    const requestBody = {
      orderId: response.info.id,
      dbId: "",
      // dbId: response.info.created_by,
      updatedBy: localStorage.getItem("userId"),
      userId: response.info.user_id,
      status: statusCode,
      statusText: getStatusText(statusCode),
      notes: getStatusText(statusCode),
    };

    //console.log("requestBody", requestBody);
    await dispatch(updateStatusRequestAction(requestBody)).then((response) => {
      if (response.status == true) {
        dispatch(
          displayCancelAlert({
            displayCancel: false,
            formData: null,
          })
        );
        if (props.onSubmit) props.onSubmit();
      } else {
        dispatch(
          displayCancelAlert({
            displayCancel: false,
            formData: null,
          })
        );
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  // const deleteItem = async () => {
  //   await axiosApi
  //     .delete(API_CONFIG.API_HOST + deleteApiPath + itemInfo.id, {
  //       headers: API_HEADERS,
  //     })
  //     .then((response) => {
  //       dispatch(
  //         displayRemoveAlert({
  //           displayRemove: false,
  //           formData: null,
  //           deleteApiPath: "",
  //         })
  //       );
  //       if (props.onSubmit) props.onSubmit();
  //     });
  // };
  return (
    <>
      <div
        style={{
          position: "fixed",
          backgroundColor: "rgba(0,0,0,0.5)",
          width: "100%",
          height: "100%",
          zIndex: "999",
          overflow: "scroll",
        }}
      >
        <div className="d-flex align-item-center justify-content-center">
          <div className="col-md-8">
            <div className="container">
              <div className="row mt-5">
                <div className="col-md-5 card-1 p-5">
                  <h4 className="text-center">
                    Cancel {INVOICE_PREFIX}
                    {response.info.id}
                  </h4>
                  <p className="text-center">
                    Are you sure you want to Cancel Order?
                  </p>

                  <div className="col-md-12">
                    <div className="form-group">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 20,
                        }}
                      >
                        <button
                          style={{ display: "flex", justifyContent: "center" }}
                          type="button"
                          className="btn btn-purple-bg"
                          onClick={() => {
                            updateStatus(6);
                          }}
                        >
                          Ok
                        </button>
                        <button
                          className="btn btn-white-bg"
                          onClick={() =>
                            dispatch(
                              displayCancelAlert({
                                displayCancel: false,
                                formData: null,
                              })
                            )
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AlertCancelOrder;
