import { useEffect } from "react";
import { FaWindowClose } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { displayLargeImage } from "../redux/slice/formUpdateSlice";

function FullImageView(props) {
  const dispatch = useDispatch();

  useEffect(() => {}, []);
  return (
    <div
      style={{
        position: "fixed",
        backgroundColor: "rgba(0,0,0,0.5)",
        width: "100%",
        height: "100%",
        zIndex: "999",
        overflow: "scroll",
      }}
    >
      <div className="d-flex align-item-center justify-content-center">
        <div className="col-md-8">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-5 card-1 p-5">
                <FaWindowClose
                  size={26}
                  color="red"
                  style={{ float: "right" }}
                  onClick={() => {
                    if (props.onCancelClick) {
                      props.onCancelClick(false);
                    }

                    dispatch(
                      displayLargeImage({
                        displayLargeImage: false,
                        formData: {},
                      })
                    );
                  }}
                />

                <h6>
                  {props.product.product != undefined
                    ? props.product.product.item.name
                    : props.product.item.name}
                </h6>
                <img
                  style={{
                    objectFit: "cover",
                  }}
                  src={
                    props.product.product != undefined
                      ? props.product.product.item.preview_image_path
                      : props.product.item.image_path
                  }
                  alt={""}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FullImageView;
