import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  displayForm,
  formUpdateStatus,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";

import PriceEntryForm from "./price-edit-form";
import { priceListAction } from "../../network/store/action/ProductResponseAction";

function ProductPrice(props) {
  const dispatch = useDispatch();
  const showRequestForm = useSelector(formUpdateStatus);
  // list of items
  const [resultList, setResultList] = useState([]);
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    await dispatch(priceListAction({ productId: props.id }, dispatch)).then(
      (reponse) => {
        if (reponse.result) {
          setResultList(reponse.result);
        }
      }
    );
  };

  return (
    <>
      {showRequestForm && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <PriceEntryForm
            id={props.id}
            onSubmitClick={() => {
              dispatch(displayForm({ displayForm: false }));
              loadData();
            }}
            onCancelClick={() => {
              dispatch(displayForm({ displayForm: false }));
            }}
          />
        </div>
      )}
      <div className="d-flex align-item-center justify-content-center">
        <div className="col-md-8">
          <div
            className="container  card-1"
            style={{ minHeight: "300px", width: "100%" }}
          >
            <div className="row mt-5 mb-5">
              <div className="col-md-12" style={{ padding: "20px" }}>
                <div className="row justify-content-around tble">
                  <div className="col-6">
                    <h5 className="dashboard-title">Product Prices</h5>
                  </div>
                  <div className="mr-auto col-6 text-end">
                    <button
                      type="button"
                      className="mr-auto btn btn-purple-bg"
                      onClick={() => {
                        dispatch(displayForm({ displayForm: true }));
                      }}
                    >
                      Add Price
                    </button>
                  </div>
                </div>
              </div>
              {resultList && resultList.length > 0 ? (
                <table className="table border-sm  mt-2">
                  <thead>
                    <th>QTY UOM</th>
                    <th>Dimension</th>
                    <th>Color</th>
                    <th>Purchase/Mfg Cost</th>
                    <th>B2C Price</th>
                    <th>B2C Offer</th>
                    <th>B2B Price</th>
                    <th>B2B Offer</th>
                  </thead>
                  <tbody>
                    {resultList.map((item, i) => {
                      return (
                        <tr>
                          <td>
                            {item.qty} {"-"}
                            {item.measure}
                          </td>
                          <td>{item.dimension}</td>
                          <td>{item.colorName}</td>
                          <td>₹{item.purchase_price}</td>
                          <td>₹{item.price}</td>
                          <td>
                            {item.offer}% - ₹{item.offer_price}
                          </td>
                          <td>₹{item.b2bprice}</td>
                          <td>
                            {item.b2boffer}% - ₹{item.b2boffer_price}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p>No Data found</p>
              )}
              {resultList && resultList.length > 0 && (
                <button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "150px",
                    marginTop: "30px",
                    marginLeft: "40%",
                  }}
                  type="button"
                  className="btn btn-primary col-lg-6"
                  onClick={() => {
                    if (props.onSubmitClick) props.onSubmitClick();
                  }}
                >
                  Continue
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductPrice;
