import { useNavigate } from "react-router-dom";
import { listAction as storeListAction } from "../../../network/store/action/BranchResponseAction";
import { useDispatch, useSelector } from "react-redux";
import {
  formUpdateData,
  formUpdateStatus,
  largeImageStatus,
} from "../../../redux/slice/formUpdateSlice";
import { listAction } from "../../../network/store/action/UserResponseAction";
import { useEffect, useState } from "react";
import CustomerItem from "./customer-item";
import { changeStatus } from "../../../redux/slice/loaderSlice";
import FullImageView from "../../../components/full-image";

function Customer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // list of items
  const [resultList, setResultList] = useState([]);
  const showRequestForm = useSelector(formUpdateStatus);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  const [filterList, setFilterList] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const [branches, setBranchList] = useState([]);
  const [filterOptions, setfilterOptions] = useState({
    orgId: "",
  });

  const formData = useSelector(formUpdateData);
  const displayLargeImage = useSelector(largeImageStatus);

  useEffect(() => {
    getStores();
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [filterOptions]);

  const getStores = async () => {
    await dispatch(storeListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setBranchList(response.result);
      }
    });
  };

  const handleChanges = (e) => {
    if (e.target.name === "orgId") {
      branches.findIndex((obj) => obj.id === e.target.value);
      setfilterOptions({
        ...filterOptions,
        orgId: e.target.value,
      });
    } else {
      setfilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
    }
  };
  const loadData = async () => {
    dispatch(changeStatus(true));
    await dispatch(
      listAction(
        {
          orgId: filterOptions.orgId,
          roleId: 5,
        },
        dispatch
      )
    ).then((reponse) => {
      dispatch(changeStatus(false));
      setFilterList([]);

      if (reponse.result) {
        setResultList(reponse.result);
      } else {
        setResultList([]);
      }
    });
  };

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);
    if (searchTerm === "") {
      setResultList(resultList);
      setFilterList([]);
    } else {
      const filterBySearch = resultList.filter((item) => {
        if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return item;
        }
      });
      setFilterList(filterBySearch);
    }
  }

  return (
    <>
      {displayLargeImage && (
        <>
          <FullImageView
            product={formData}
            onCancelClick={(reloadStatus) => {}}
          />
        </>
      )}
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">App Users</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  navigate("/usercu");
                }}
              >
                + Add
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-3">
            <div className="form-group">
              <label>Search</label>
              <input
                type="search"
                name="name"
                className="form-control"
                value={searchVal}
                placeholder="Enter name"
                onChange={(e) => handleSearchClick(e)}
              />
            </div>
          </div>
          {/* <div className="col-md-3">
          <div className="form-group">
            <label>Branch</label>
            <select
              name="orgId"
              className="form-select"
              onChange={(e) => handleChanges(e)}
              value={filterOptions.sectionId}
            >
              <option value={""}>{"--Select Branch--"}</option>
              {branches &&
                branches.length > 0 &&
                branches.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div> */}

          <div className="col-md-12 card-1 table-responsive">
            <table className="table table-striped table-bordered border-sm  mt-2 align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Sno</th>
                  <th scope="col">City</th>
                  <th scope="col">Contact Name</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>

              {searchVal === "" && resultList && resultList.length > 0 ? (
                <tbody>
                  {resultList.map((item, i) => {
                    return <CustomerItem item={item} key={item.id} />;
                  })}
                </tbody>
              ) : filterList.length > 0 ? (
                <tbody>
                  {filterList.map((item, i) => {
                    return <CustomerItem item={item} key={item.id} />;
                  })}
                </tbody>
              ) : (
                <p>No Data found</p>
              )}
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default Customer;
