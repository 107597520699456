import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { ORDER_API_PATH } from "../../network/config/apiPaths";
import OrdersItem from "./order-item";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { todayDate } from "../../utils/dateUtil";

function InprogressOrders(props) {
  const [orderResult, setOrderResult] = useState([]);
  const statusIds = [1, 2, 3];
  const navigate = useNavigate();
  useEffect(() => {
    OrderList();
  }, [props.storeId, props.refresh]);

  async function OrderList() {
    setOrderResult([]);
    const data = await axios.get(
      `${API_CONFIG.API_HOST}${ORDER_API_PATH.ORDER_LIST}?limit=10&offset=0&status=${statusIds}&storeId=${props.storeId}`,
      {
        headers: API_HEADERS,
      }
    );

    setOrderResult(data.data.result);
  }

  return (
    <div className="table-responsive">
      <table className="table table-striped table-bordered border-sm  mt-2">
        <thead className="thead-dark">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Branch</th>
            <th scope="col">Order Time</th>
            <th scope="col">Order Amount</th>
            <th scope="col">Customer Details </th>
            <th scope="col">Coupon</th>
            <th scope="col">Status </th>
            <th scope="col">Payment Mode </th>
            <th scope="col">Last Update Time</th>
          </tr>
        </thead>

        <tbody>
          {orderResult && orderResult.length > 0 ? (
            orderResult.map((obj) => {
              return <OrdersItem order={obj} key={obj.id} />;
            })
          ) : orderResult === null ? (
            <>
              <h6>No Recent Orders</h6>
            </>
          ) : (
            <>
              <h6>Loading...</h6>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}
export default InprogressOrders;
